/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
    {
        name: "Mein Arbeisplatz",
        slug: "home",
        icon: "HomeIcon",
        submenu: [
            {url: '/', name: "Dashboard", slug: "dashboard"},
            {url: '/calendar', name: "Kalender", slug: "calendar"},
            {url: '/scheduler', name: "Resourcen Planer", slug: "scheduler"}
        ]
    }, {
        name: "Arbeitszeit",
        slug: "working-hours",
        icon: "ClockIcon",
        submenu: [
            {url: '/holidays', name: "Urlaube", slug: "holidays"},
            {url: 'http://192.168.178.26/timetracking/stempelterminal.php', name: "Stempelterminal", slug: "external", target: "_blank"},
            {url: '/working-time-report', name: "Arbeitszeitbericht", slug: "working-time-report"},
        ]
    }, {
        name: "Organisationen",
        slug: "organization",
        icon: "BriefcaseIcon",
        submenu: [
            {url: '/customers', name: "Kunden", slug: "customers"},
            {url: '/contacts', name: "Kontakte", slug: "contacts"},
            {url: '/suppliers', name: "Lieferanten", slug: "suppliers"},
        ]
    }, {
        name: "Vertrieb",
        slug: "distribution",
        icon: "TruckIcon",
        submenu: [
            {url: '/offers', name: "Angebote", slug: "offers"},
            {url: '/invoices', name: "Belege (Rechnungen)", slug: "invoices"},
            {url: '/contracts', name: "Verträge", slug: "contracts"},
            {url: '/products-and-services', name: "Produkte & Dienstleistungsartikel", slug: "products-and-services"},
            {url: '/accountings', name: "Abrechnung", slug: "accountings"},
            {url: '/planned-sales', name: "Planumsätze", slug: "plannedsales", permissionNeeded:'view_planned_sales'},
            {url: '/b2b-sellers/demoshop', name: "B2Bsellers Demoshops", slug: "b2bsellers-demoshops", workspaceId:'2'},
            {url: '/licence-manager', name: "B2Bsellers Lizenzmanager", slug: "licence-manager", workspaceId:'2'},
        ]
    },
    {
        name: "Projekte",
        slug: "projects",
        icon: "CheckCircleIcon",
        submenu: [
            {url: '/sprints', name: "Sprints", slug: "sprint-list"},
            {url: '/processes', name: "Vorgangsmanager", slug: "processes-list"},
        ]
    },
    {
        name: "Monitoring",
        slug: "monitoring",
        icon: "MonitorIcon",
        submenu: [
            {url: '/ssl-certificates', name: "SSL-Zertifikate", slug: "ssl-certificates"},
            {url: '/domains', name: "Domains Uptime", slug: "domains"},
            {url: '/domain-uptime-robot', name: "Domains Uptime-Robot", slug: "domains-uptime"},
        ]
    },
    {
        url: "/docs",
        name: "Knowledge Base",
        slug: "documentation",
        icon: "MonitorIcon",
    }, {
    name: "Buchhaltung",
    slug: "accounting",
    icon: "ClipboardIcon",
    submenu: [
      {url: '/bank-transactions', name: "Bank Transaktionen", slug: "bank-transactions", permissionNeeded:'view_bank_transactions'},
      {url: '/document-management', name: "Belegverwaltung", slug: "documents", permissionNeeded:'view_bank_transaction_documents'},
      {url: '/billing-run', name: "Rechnungslauf", slug: "billingrun", permissionNeeded:'view_bank_transaction_documents'},
      {url: '/bills-mailbox', name: "Postfach Rechnungen", slug: "billingmails", permissionNeeded:'view_bank_transaction_documents'},
      {url: '/controlling/contract', name: "Vertrags-Controlling", slug: "contract-controlling", permissionNeeded:'show_controlling'},
      {url: '/controlling', name: "Controlling", slug: "controlling", permissionNeeded:'show_controlling'},
      {url: '/bonus-statistic', name: "Bonus Statistik", slug: "bonus-statistik", permissionNeeded:'show_controlling'},
    ]
  }, {
        name: "Human Ressources",
        slug: "human-resources",
        icon: "UsersIcon",
        submenu: [
            {url: '/employees', name: "Mitarbeiter", slug: "employees",},
            {url: '/teams', name: "Teams", slug: "teams",},
            {url: '/manage-commissions', name: "Meine Provisionen", slug: "manage-commissions",},
            {url: '/manage-reimbursement', name: "Kostenersatz verwalten", slug: "reimbursement",permissionNeeded:'user_can_manage_reimbursements'},
            {url: '/manage-lunchbreak-cash-desk', name: "Mittagspause Kasse", slug: "lunchbreak-cash-desk",permissionNeeded:'manage_lunchbreak_cash_desk'},
        ]
    }, {
        url: "/activity-protocol",
        name: "Aktivitätsprotokoll",
        slug: "activity-protocol",
        icon: "MonitorIcon",
        permissionNeeded:'show_controlling'
    }, /* {
    url: "/documents",
    name: "Dokumente",
    slug: "documents",
    icon: "FileIcon",
  },  {
    name: "Marketing",
    slug: "marketing",
    icon: "SendIcon",
    submenu: [
      {url: '/mailings', name: "Mailings", slug: "mailings",},
      {url: '/campaigns', name: "Kampagnen", slug: "campaigns",},
    ]
  }, */ {
        name: "Administration",
        slug: "administration",
        icon: "SlidersIcon",
        submenu: [
            {url: '/permissions', name: "Berechtigungen", slug: "permissions", permissionNeeded:'permissions'},
            {url: '/general-settings/general', name: "System Einstellungen", slug: "general-settings", permissionNeeded:'permissions'},
            {url: '/email-addresses', name: "E-Mail Adressen", slug: "email-addresses", permissionNeeded:'permissions'},
            {url: '/payment-methods', name: "Bezahlarten", slug: "payment-methods", permissionNeeded:'permissions'},
            {url: '/administrate-invoices', name: "Rechnungen", slug: "administrate-invoices", permissionNeeded:'permissions'},
            {url: '/bank-api', name: "Banken-API", slug: "bank-api", permissionNeeded:'permissions'},
            {url: '/email-templates', name: "E-Mail Templates", slug: "email-templates"},
            {url: '/administrate-workflows', name: "Workflows", slug: "administrate-workflows"},
            {url: '/data-import', name: "Import", slug: "import", permissionNeeded:'permissions'},
        ]
    }, /* {
    url: "/support",
    name: "Support",
    slug: "support",
    icon: "MessageSquareIcon",
  },  {
    name: "Projektverwaltung",
    slug: "project-management",
    icon: "EditIcon",
    submenu: [
      {url: '/projects', name: "Projekte", slug: "projects"},
      {url: '/reports', name: "Reports", slug: "reports"},
      {url: '/protocols', name: "Protokolle", slug: "protocols"},
    ]
  }, */ {
        url: "/debugging",
        name: "Debugging",
        slug: "debugging",
        icon: "CodeIcon",
        permissionNeeded:'permissions'
    },
]
